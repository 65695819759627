import React, { useState, useEffect } from "react";
import SubHeaderComponent from "../../../components/shared/Subheader/subHeader.component";
import { StripeProduct } from "../../../types/stripe";
import {
  getStripeProducts,
  renewStripeSubscription,
} from "../../../api/stripeApi";
import Swal from "sweetalert2";
import LoaderComponent from "../../../components/shared/Loader/Loader.component";
import { useNavigate, useParams } from "react-router-dom";
import { set } from "date-fns";
import { getUser, searchUser } from "../../../api/usersApi";

const RenewMembershipScreen = () => {
  const navigate = useNavigate();
  const { customerId } = useParams();
  const [selected, setSelected] = useState("");
  const [products, setProducts] = useState<StripeProduct[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [selectedPaymentType, setSelectedPaymentType] = useState("one_time_s");

  const setError = (error: string) => {
    console.log("error", error);
    Swal.fire({
      icon: "error",
      title: "Error",
      text: error,
    });
  };

  const validateUserData = async () => {
    try {
      if (!customerId) {
        navigate("/login");
        return;
      }
      const search = await searchUser(
        0,
        1,
        "",
        { stripeCustomerId: customerId },
        ""
      );
      if (!search.docs || !search.docs.length) {
        navigate("/login");
        return;
      }
      const user = search.docs[0];
      const userData = await getUser(user._id || "", "");
      if (userData.stripeCustomerId !== customerId) {
        navigate("/login");
        return;
      }
      const { stripeSubscription } = userData;
      if (stripeSubscription && stripeSubscription.length) {
        const ableToRenew = stripeSubscription.some(
          (sub) => sub.renewCandidate === true
        );
        if (!ableToRenew) {
          return Swal.fire({
            icon: "error",
            title: "Error",
            text: "Tu suscripción no puede ser renovada ya que aún se encuentra activa.",
          }).then(() => {
            navigate("/login");
          });
        }
      }
      await fetchStripeProducts();
    } catch (error) {
      setError((error as any).message);
    }
  };

  const fetchStripeProducts = async () => {
    try {
      const products = await getStripeProducts("");
      if (products) {
        setProducts(products);
        setIsLoading(false);
      }
    } catch (error) {
      setError((error as any).message);
    }
  };

  const handleRenew = async () => {
    try {
      setIsLoading(true);
      const price = selected;
      if (customerId && price) {
        const result = await renewStripeSubscription("", {
          customer: customerId,
          price,
        });
        if (result) {
          Swal.fire({
            icon: "success",
            title: "Success",
            text: "Tu suscripción ha sido renovada con éxito.",
          });
          navigate("/");
        }
      }
    } catch (error) {
      setError((error as any).message);
    }
  };

  useEffect(() => {
    validateUserData();
  }, []);

  return (
    <>
      <SubHeaderComponent href={"/login"} buttonText={"Regresar"} />
      <section className="pt-5 mt-5 membership-layout">
        <div className="container">
          <div className="row membership--top">
            <div className="membership__col-left col-md-4 col-12">
              <div className="form-row">
                <h1 className="h3">
                  Renueva tu suscripción y obtén beneficios exclusivos y
                  funciones adicionales con SOMA+.
                </h1>
              </div>
              {isLoading && (
                <div className="mb-4">
                  <LoaderComponent isLoading={isLoading} />
                </div>
              )}
              <div className="form-row">
                <div className="form-check form-check-inline">
                  <input
                    className="form-check-input"
                    type="radio"
                    name="inlineRadioOptions"
                    id="inlineRadio1"
                    value="one_time_s"
                    checked={selectedPaymentType === "one_time_s"}
                    onChange={(e) => setSelectedPaymentType(e.target.value)}
                  />
                  <label className="form-check-label" htmlFor="inlineRadio1">
                    Pago único
                  </label>
                </div>
                <div className="form-check form-check-inline">
                  <input
                    className="form-check-input"
                    type="radio"
                    name="inlineRadioOptions"
                    id="inlineRadio2"
                    value="monthly"
                    checked={selectedPaymentType === "monthly"}
                    onChange={(e) => setSelectedPaymentType(e.target.value)}
                  />
                  <label className="form-check-label" htmlFor="inlineRadio2">
                    Mensual
                  </label>
                </div>
              </div>
              {products
                .map((product) =>
                  product.prices.data.filter((price) => {
                    const { metadata } = price;
                    if (metadata) {
                      const { payment_type } = metadata;
                      return payment_type === selectedPaymentType;
                    }
                    return false;
                  })
                )
                .flat() // Aplanamos el array de arrays
                .sort((a, b) => {
                  const { metadata: metadataA } = a;
                  const { metadata: metadataB } = b;
                  if (metadataA && metadataB) {
                    const { order: orderA } = metadataA;
                    const { order: orderB } = metadataB;
                    if (orderA && orderB) {
                      return orderA - orderB;
                    }
                  }
                  return 0;
                })
                .map((price, index) => {
                  const { metadata } = price;
                  return (
                    <div className="form-row" key={index}>
                      <div
                        className={`form-check check--left check--block ${
                          selected === price.id ? "active" : ""
                        }`}
                      >
                        <input
                          className="form-check-input"
                          type="radio"
                          name="membership"
                          value="yearly"
                          id={`membership-${index}`}
                          checked={selected === price.id}
                          onChange={() => setSelected(price.id)}
                        />
                        <label
                          className="form-check-label"
                          htmlFor={`membership-${index}`}
                        >
                          {`${metadata.Description}`}
                        </label>
                      </div>
                    </div>
                  );
                })}
            </div>
            <aside className="membership__col-right col-md-8 col-12">
              <figure className="image">
                <img
                  src="/assets/images/elements/frame-membership.png"
                  alt="Membership"
                />
              </figure>
            </aside>
          </div>
          <div className="row membership--bottom">
            <div className="mx-auto mb-3 text-center col-md-8 col-12">
              <button
                type="button"
                className="btn btn--type1 btn--large"
                disabled={selected !== "" ? false : true}
                onClick={handleRenew}
              >
                Continuar
              </button>
            </div>
            <div className="mx-auto text-center col-md-8 col-12">
              <a href="/login" className="btn btn--type2 btn--large">
                Completar más tarde
              </a>
              <p className="mt-4 gray-color700">
                <small>
                  Renovación automática. Cancela al menos 24 horas antes de la
                  fecha de renovación.
                </small>
              </p>
            </div>
            <div className="col-12">
              <ul className="pb-5 list-terms">
                <li>
                  <a href="/terms">Términos de uso</a>
                </li>
                <li>
                  <a href="/privacy">Aviso de privacidad</a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default RenewMembershipScreen;
